
  import { defineComponent, computed, onMounted, watch } from "vue";
  import { Form, Field, ErrorMessage } from "vee-validate";
  import ApiService from "@/core/services/ApiService";
  import { useBus } from '@/bus';
  import $ from 'jquery';
  import 'datatables.net';
  import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
  import Datatable from '@/components/kt-datatable/KTDatatable.vue';
  import { VueCookieNext } from "vue-cookie-next";
  import KTEditor from "@/layout/toolbar/QuillEditor.vue";

 
export default defineComponent({
    name: "Task",
    components: {
      Form,
      Field,
      ErrorMessage,
      Datatable,
      KTEditor,
    },
    data() {
      return {
        loading: false,
        taskList: [] as any,
        initData:[] as any,
        taskKeyUpdate: 0,
        search: '',
        componentKey:0,
        user:[] as any,
        employee_id: '' as any,
        role_id:'' as any,
        all_cookies: '' as any,
        filterStatus: '' as any,
        tableHeader: [
          {
            name: 'TASK No.',
            key: 'index',
          },
          {
            name: 'UPAZILLA',
            key: 'upazila_name',
          },
          {
            name: 'GRID',
            key: 'grid_name',
          },
          {
            name: 'DEADLINE',
            key: 'deadline',
          },
          {
            name: 'ASSIGNED BY',
            key: 'assigned_by',
          },
          {
            name: 'ASSIGNING DATE',
            key: 'assigning_date',
          },
          {
            name: 'Actions',
            key: 'actions',
          },
        ]
      };
    },
    async created() {
      await this.getAdminTaskList();
      this.initData= this.taskList;
    },
    methods: {
      async getAdminTaskList() {
        this.loading=true;
        this.user= VueCookieNext.getCookie("_seip_employee_id");
        this.employee_id= VueCookieNext.getCookie("_seip_employee_id");
        this.role_id= VueCookieNext.getCookie("_seip_role_id");
       
        if(this.$route.params.id!=''){
          this.role_id=this.$route.params.id;
        }
        await ApiService.get("configurations/task/userwise-list?role_id=" +this.role_id+"&current_status=1")
          .then((response) => {
            this.taskList = response.data.data;
            this.taskKeyUpdate+=1;
            this.loading=false
          })
          .catch(({ response }) => {
            console.log(response);
          });
      },
      assignQc(taskDetail){
        const { bus } = useBus();
        let value = {
          taskDetail: taskDetail,
        };
        bus.emit('task-data-assign', value);
      },
      taskUpload(task){
        const { bus } = useBus();
        let value = {
          taskDetail: task,
        };
        bus.emit('task-data-upload', value);
      },
      receivedTaskView(task){
        const { bus } = useBus();
        let value = {
          taskDetail: task,
        };
        bus.emit('received-data-view', value);
      },

      filterTasks() {
        if(this.search=='')
        {
          this.taskList= this.initData;
          this.taskKeyUpdate +=1;
        }
        const tasks = this.taskList.filter(taskDetail => {
          return (
            taskDetail.deadline.toLowerCase().includes(this.search.toLowerCase()) ||
            taskDetail?.task?.upazila_info?.upazila_name_eng.toLowerCase().includes(this.search.toLowerCase()) ||
            taskDetail?.task?.grid_info?.grid_name.toLowerCase().includes(this.search.toLowerCase()) ||
            taskDetail?.assigning_date.toLowerCase().includes(this.search.toLowerCase()) ||
            taskDetail?.assign_by_employee_info?.name.toLowerCase().includes(this.search.toLowerCase())
          );
        });

        this.taskList= tasks;
        this.taskKeyUpdate +=1;
      },
    },
  });
  